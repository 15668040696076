@import '../../styles/colors.scss';
@import '../../styles/radius.scss';
@import '../../styles/fontSizes.scss';

.SOUS-CHEF__login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  overflow: hidden;
  height: 100%;
  background: url(../../../public/background.png) no-repeat center fixed;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;

  &__content {
    background-color: $primary-inverted;
    min-height: 250px;
    border-radius: $rounded-xl;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    padding: 4rem 2rem 3rem;
    text-align: center;
    width: 420px;
    max-width: 100%;
    font-size: $sm;
    position: relative;
    overflow: hidden;

    &__multifactor-input {
      width: 32px;
      height: 32px;
      background-color: $border;
      text-align: center;
      outline: none;
      border: none;
      border-radius: 2px;
    }

    &__icon-button {
      border: 2px solid $border;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      padding: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      transition: background-color 0.25s ease-in-out;
      cursor: pointer;

      &:hover {
        background-color: $border;
      }

      svg {
        max-width: 100%;
      }
    }
  }
}